import '@mantine/carousel/styles.css';
import '@mantine/core/styles.css';
import './App.css';
import { HeroBullets } from './components/hero/HeroBullets';

function App() {
  return (
    <div className="App">
      <HeroBullets />
    </div>
  );
}

export default App;
