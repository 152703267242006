import { Center, Container, Grid, Group, List, Modal, Text, ThemeIcon, Title, UnstyledButton } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconBrandAppleFilled, IconBrandGooglePlay, IconCheck } from '@tabler/icons-react';
import { CardsCarousel } from '../carousel/CardsCarousel';
import classes from './HeroBullets.module.css';

export function HeroBullets() {
    const iconGoogleStore = <IconBrandGooglePlay size={24} style={{ padding: 0, margin: 0 }} />;
    const iconAppleStore = <IconBrandAppleFilled size={24} style={{ padding: 0, margin: 0 }} />;
    const [opened, { open, close }] = useDisclosure(false);
    return (
        <Container size="xl"
        >
            <div className={classes.inner}
            >
                <Grid>
                    <Grid.Col span={{ xl: 6, lg: 6, md: 12, sm: 12, xs: 12 }}>
                        <div className={classes.content}>
                            <Title className={classes.title}>
                                <span className={classes.highlight}>Glam</span> yourself with AI
                            </Title>
                            <Text c="dimmed" mt="md">
                                Discover your style with Zaiglam, transform your look effortlessly with our AI-powered app.
                                Experience personalized styling and grooming recommendations tailored just for you.
                            </Text>

                            <List
                                mt={30}
                                spacing="sm"
                                size="sm"
                                icon={
                                    <ThemeIcon size={20} radius="xl">
                                        <IconCheck style={{ width: 12, height: 12 }} stroke={1.5} />
                                    </ThemeIcon>
                                }
                                style={{
                                    textAlign: 'left',
                                }}
                            >
                                <List.Item>
                                    <b>AI-Driven Personalization</b> – Zaiglam uses advanced AI to understand your unique features, offering styling and grooming recommendations that are perfectly tailored to you. Experience the future of personalization.
                                </List.Item>
                                <List.Item>
                                    <b>Effortless Style Transformation</b> – With just one click, Zaiglam transforms your look, providing expert advice on the latest trends in makeup and grooming. Discover effortless beauty and style, curated just for you.
                                </List.Item>
                                <List.Item>
                                    <b>Explore and Shop with Ease</b> – Zaiglam not only inspires your next look but also connects you to the products you need. Explore a curated selection of the latest styling essentials and shop with ease, all within the app.
                                </List.Item>
                            </List>
                            <Center>
                                <Group mt={20}>
                                    <UnstyledButton onClick={open}>
                                        <img src={'/images/app-store.webp'} alt="Download on the App Store"
                                            style={{ cursor: 'pointer', height: 50, }}
                                        />
                                    </UnstyledButton>
                                    <UnstyledButton onClick={open}>
                                        <img src={'/images/play-store.webp'} alt="Get it on the Play Store"
                                            style={{ cursor: 'pointer', height: 50, }}
                                        />
                                    </UnstyledButton>
                                </Group>
                            </Center>
                        </div>
                    </Grid.Col>
                    <Grid.Col span={{ xl: 6, lg: 6, md: 12, sm: 12, xs: 12 }}>
                        <div className={classes.carouselConainer}>
                            <CardsCarousel />
                        </div></Grid.Col>
                </Grid>



                <Modal opened={opened} onClose={close} title="Coming soon" centered>
                    Please be advised that our team is currently hard at work developing the Zaiglam platform.
                    <br />
                    <br /> Stay tuned for updates on our progress and the official launch date <a href="mailto:info@zaiglam.com">info@zaiglam.com</a>
                    <br />
                    <br />
                </Modal>
            </div>
        </Container>
    );
}